import React, { useEffect, useState, useRef } from "react";
import Styles from "./Categories.module.css";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {
  UpdateProviderCategoriesListOptions,
  getProviderCategoriesList,
  getProviderCategoriesListOptions,
} from "../../../config/api";
import { useParams, useLocation } from "react-router-dom";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const Categories = ({ langConfig, langData, isAdmin, providerName, id, setChangeInCategories, setSavedCategories }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [categoriesList, setCategoriesList] = useState([]);
  const [categoriesLoading, setCategoriesLoading] = useState(false);
  const [categoriesOptionsList, setCategoriesOptionsList] = useState([]);
  const [visibleOptionsCount, setVisibleOptionsCount] = useState(10);
  const selectRef = useRef(null);

  const handleOptionChange = (event, option, index) => {
    setCategoriesList((prevCategoriesList) => {
      const updatedList = [...prevCategoriesList];
      const oldSystemCategories = updatedList[index].systemCategories.filter(cat => cat.id !== option.id);
      const updatedOption = { ...option, isChecked: event.target.checked };
      updatedList[index].systemCategories = [...oldSystemCategories, updatedOption];
      updatedList[index].systemCategoryId = option.id;
      return updatedList;
    });
  };

  const handleChange = (event) => {
    setChangeInCategories(true);
    setSavedCategories(false);
  };

  const combinedHandler = (event, option, index) => {
    handleOptionChange(event, option, index);
    handleChange(event);
  };

  const fetchCategoriesList = async () => {
    try {
      setCategoriesLoading(true);
      const response = await axios.get(getProviderCategoriesList(id));
      const data = response.data;

      const updatedCategoriesList = data.data.map(item => ({
        ...item,
        systemCategories: item.systemCategories.length > 0 ? item.systemCategories : categoriesOptionsList.map(option => ({ ...option, isChecked: false }))
      }));

      setCategoriesList(updatedCategoriesList);
      console.log("Fetched categoriesList:", updatedCategoriesList);
      setCategoriesLoading(false);
    } catch (error) {
      console.error(error);
      setCategoriesLoading(false);
    }
  };

  const fetchCategoriesListOptions = async () => {
    try {
      setCategoriesLoading(true);
      const response = await axios.get(getProviderCategoriesListOptions());
      const data = response.data;
      setCategoriesOptionsList(data.data);
      console.log("Fetched categoriesOptionsList:", data.data);
      setCategoriesLoading(false);
    } catch (error) {
      console.error(error);
      setCategoriesLoading(false);
    }
  };

  const updateCategoriesListOptions = async () => {
    try {
      setCategoriesLoading(true);
      await axios.post(UpdateProviderCategoriesListOptions(), categoriesList);
      setSavedCategories(true);
      setCategoriesLoading(false);
      await fetchCategoriesList();
    } catch (error) {
      console.error(error);
      setCategoriesLoading(false);
    }
  };

  const openCategoryProducts = (id, name) => {
    navigate(`/selected-category-product/${id}`, { state: { categoryName: name } });
  };

  useEffect(() => {
    fetchCategoriesListOptions().then(fetchCategoriesList);
  }, []);

  useEffect(() => {
    console.log("Updated categoriesList:", categoriesList);
  }, [categoriesList]);

  useEffect(() => {
    console.log("Updated categoriesOptionsList:", categoriesOptionsList);
  }, [categoriesOptionsList]);

  const handleScroll = (event) => {
    console.log("Scrolled:", event.target.scrollTop); // Debugging log
    const bottom = event.target.scrollHeight - event.target.scrollTop <= event.target.clientHeight + 1;
    if (bottom) {
      setVisibleOptionsCount((prevCount) => prevCount + 30);
    }
  };

  return (
    <div className={Styles.containerUser}>
      <div className={Styles.filterBox}>
        <div className="d-flex justify-content-end align-items-center">
          <button
            className={Styles.btnStyle}
            onClick={updateCategoriesListOptions}
          >
            שמור
          </button>
        </div>
      </div>

      <div className="card mt-5">
        <div className="card-header"></div>
        <div className="card-body">
          <div className={Styles.tableResponsive}>
            <table className={`${Styles.table} ${Styles.tableStriped}`}>
              <thead>
                <tr>
                  <th>{isAdmin ? "קטגוריות מנהל" : "User Category Title"}</th>
                  <th>קטגורית ספק</th>
                  <th>System Category</th>
                  {!isAdmin && <th></th>}
                </tr>
              </thead>
              {!categoriesLoading ? (
                <tbody>
                  {categoriesList.length > 0 ? (
                    categoriesList.map((item, index) => (
                      <tr key={index}>
                        <td>
                          <FormControl sx={{ m: 1, width: 300 }}>
                            <InputLabel id={`demo-multiple-checkbox-label-${index}`}>
                              קטגוריות
                            </InputLabel>
                            <Select
                              labelId={`demo-multiple-checkbox-label-${index}`}
                              id={`demo-multiple-checkbox-${index}`}
                              multiple
                              value={categoriesList[index].systemCategories.filter(cat => cat.isChecked).map(cat => cat.niceName)}
                              input={<OutlinedInput label="Categories" />}
                              renderValue={(selected) => selected.join(', ')}
                              MenuProps={{
                                ...MenuProps,
                                PaperProps: {
                                  ...MenuProps.PaperProps,
                                  onScroll: handleScroll, // Attach scroll event
                                },
                              }}
                              ref={selectRef}
                            >
                              {categoriesList[index].systemCategories.slice(0, visibleOptionsCount).map((option, indexInner) => (
                                <MenuItem key={indexInner} value={option.niceName}>
                                  <Checkbox
                                    checked={option.isChecked}
                                    onChange={(event) => combinedHandler(event, option, index)}
                                  />
                                  <ListItemText primary={option.adminName} />
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </td>
                        <td>{item.niceName}</td>
                        <td>{item.systemCategory}</td>
                        {!isAdmin && (
                          <td>
                            <button
                              style={{ margin: '10px', width: '85px' }}
                              className="btn btn-primary ml-1 actionbutton"
                              onClick={() => openCategoryProducts(item.providerCategoryId, item.niceName)}
                            >
                              Products
                            </button>
                          </td>
                        )}
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="3" className="text-center py-5">
                        No data
                      </td>
                    </tr>
                  )}
                </tbody>
              ) : (
                <tbody>
                  <tr>
                    <td colSpan="3" className="text-center" style={{ height: "200px" }}>
                      <div className="spinner-border text-primary" role="status"></div>
                    </td>
                  </tr>
                </tbody>
              )}
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Categories;
