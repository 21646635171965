import React, { useState, useEffect } from 'react';
import { updateUserDetails, CreateSeller, getUser, getUserById, removeProviderById } from "../../../config/api";
import axios from "axios";
import Product from "../../pages/Product-Data/Product";
import { TextField, Button, Typography, Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useLocation, useNavigate } from "react-router-dom";
import { Form, Row, Col } from 'react-bootstrap';
import Categories from "../../pages/Categories/Categories";
import Styles from "../Product-Data/Product.module.css";
import { API_HUB_URL } from "../../../constants/envUrl";
import InputLabel from '@mui/material/InputLabel';


//import Styles from "../table.module.css";


const EditProvider = ({ langData, langConfig, isAdmin }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const pathName = location.pathname;
    const [providerDetails, setProviderDetails] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        birthday: '',
        creationDate: '',
        numberOfOrders: 0,
        totalOrders: 0,
        incCode: '',
        discountPercent: ''
    });
    const warningContainerStyle = {
        border: '2px solid #f5c6cb',
        backgroundColor: '#f8d7da',
        color: '#721c24',
        padding: '20px',
        margin: '20px',
        borderRadius: '5px',
        fontFamily: 'Arial, sans-serif',
        display: 'flex',
        alignItems: 'center',
        width: '45%'
      };
    
      const warningIconStyle = {
        fontSize: '24px',
        marginRight: '10px'
      };
    const { id } = useParams();
    const [isEditing, setIsEditing] = useState(true);
    const [user, setUser] = useState(null);
    const [selectedTab, setSelectedTab] = useState(1);
    const [changeInCategories, setChangeInCategories] = useState(false);
    const [savedCategories, setSavedCategories] = useState(false);

    const [tableType, setTableType] = useState(
        pathName === "/provider" ? "Provider" : "User"
    );
    const [requestParams, setRequestParams] = useState({
        page: 1,
        pageSize: 0,
        totalRecords: 0,
        sortIndex: -1,
        search: "",
        sortBy: "",
        userType: tableType,
        enableDisableUsers: true,
        datetime: "",
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setProviderDetails((prevDetails) => ({
            ...prevDetails,
            [name]: value,
        }));
    };

    // Utility function to format the date for the server
    const formatDateForServer = (date) => {
        if (!date) {
            return null;  // Handle null or undefined dates app
        }

        const formattedDate = new Date(date);
        const day = formattedDate.getDate().toString().padStart(2, '0');
        const month = (formattedDate.getMonth() + 1).toString().padStart(2, '0');  // Month is zero-based
        const year = formattedDate.getFullYear();

        return `${day}-${month}-${year}`;
    };

    const handleSave = async () => {
        try {
            const formattedBirthday = formatDateForServer(providerDetails.birthday);
            const formattedCreationDate = formatDateForServer(providerDetails.creationDate);


            const response = await axios.post(updateUserDetails(), {
                userId: id,
                firstName: providerDetails.firstName,
                lastName: providerDetails.lastName,
                email: providerDetails.email, 
                discountPercent: providerDetails.discountPercent,
                phoneNumber: providerDetails.phoneNumber,
                companyName: providerDetails.companyName,
                birthdate: '27-07-2023',
                creationDate : null
            });

            if (response.data.result) {
                navigate('/provider');
                window.scrollTo(0, 0); // Scroll to the top after navigation

            } else {
                alert('!!!!!!!!!!!!!!!!!!');
            }
        } catch (error) {
            console.error('Error updating user data:', error);
            alert(`Error updating user data: ${error.message}`);
        }
    };
    const convertBirthdate = (birthdate) => {
        // Split the birthdate string by '-'
        const parts = birthdate.split('-');
        
        // Extract year, month, and day from the parts
        const year = parts[0];
        const month = parts[1];
        const day = parts[2];
        
        // Format the birthdate as "DD/MM/YYYY"
        const formattedBirthdate = `${day}/${month}/${year}`;
        
        return formattedBirthdate;
    };
    
    
    const createSeller = async () => {
        try {
            const formattedBirthdate = convertBirthdate(user.birthdate);

            const sendObj = {
                SellerId: user.id,
                FirstName: user.firstName,
                LastName: user.lastName,
                Birthdate: formattedBirthdate,
                Email: user.email,
                Phone: user.phoneNumber,
                CompanyName: user.companyName
            
            };

            const response = await axios.post(
                API_HUB_URL + "/ProductCategory/createSeller",
                sendObj
            );

            console.log(response.data); // Handle response data if needed
            alert("Success");

        } catch (error) {
            console.error("Error:", error);
            alert("Failed to create seller");
        }
    };

    const formatDateForInput = (date) => {
        const formattedDate = new Date(date).toISOString().split('T')[0];
        return formattedDate;
    };

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await axios.get(getUserById(id));
                const user = response.data;
                //      alert(user)
                console.log("*******", user)
                setUser(user)
                debugger;
                setProviderDetails({
                    firstName: user.firstName ? user.firstName : ' ',
                    lastName: user.lastName ? user.lastName : ' ',
                    email: user.email ? user.email : ' ',
                    phoneNumber: user.phoneNumber ? user.phoneNumber : ' ',
                    companyName: user.companyName ? user.companyName : ' ',
                    bankAccountId: user.bankAccountId ? user.bankAccountId : ' ',
                    creationDate: user.creationDate ? formatDateForInput(user.creationDate) : ' ',
                    discountPercent: user.discountPercent? user.discountPercent:'',
                    importUrl: user.importUrl? user.importUrl : ''
                  

                });
            } catch (error) {
                console.error(error);
            }
        };

        fetchUserData();
    }, [id]);
    
    const handleDelete = async () => {
        try {
            const response = await axios.post(removeProviderById(id));

            if (response.data.result) {
                alert('User removed successfully');
                navigate('/provider');
            } else {
                alert("provider has orders, can't be removed");
            }
        } catch (error) {
            console.error('Error removing user:', error);
            alert(`Error removing user: ${error.message}`);
        }
    };
    const importProducts = (id, provider) => {
        console.log('check idd', id)
        const fullName = provider.firstName + " " + provider.lastName
        navigate(`/import-products/${id}`, { state: { fullName } });
    };
    const mapProvider = (id, provider) => {
        console.log('check idd', id)
        const fullName = provider.firstName + " " + provider.lastName
        navigate(`/categories/${id}`, { state: { fullName } });
    };
    const handleTabClick = (tabIndex) => {
        if (selectedTab === 2 && changeInCategories && !savedCategories) {
            const confirmLeave = window.confirm("שיוך קטגוריות לא נשמר");
            if (confirmLeave) {
                setSelectedTab(tabIndex);
            }
        } else {
            setSelectedTab(tabIndex);
        }
    };
    
    return (
        <>
    {/* <h1><span style={{border: '0px solid #154360', color: '#154360', marginBottom: '35px',marginTop: '30px',marginRight: '25px', fontWeight: 'bold' }} class="badge badge-secondary">עריכת ספק</span></h1>*/}

     {/*   <Typography variant="h5" style={{ color: '#154360',  marginBottom: '35px',marginTop: '30px',marginRight: '28px', fontWeight: 'bold' }}>עריכת ספק</Typography>*/}
       
            <Box
                style={{
                    marginTop: "50px",

                   // with: '70%'
                  //  backgroundColor: "red"
                  //  marginRight:20
                  
                }}
            >
                
                <nav class="nav nav-tabs" style={{color: '#154360'}}>
                    <div className="nav" id="nav-tab" role="tablist" style={{marginRight:'50px', color: '#154360'}}>
                        <a style={{color:'#154360'}} className={`nav-item nav-link ${selectedTab === 1 ? 'active show' : ''}`} id="nav-home-tab" onClick={() => handleTabClick(1)} data-toggle="tab" href="#nav-general-profile" role="tab" aria-controls="nav-home" aria-selected="false"><i className="fa fa-building-o show-mob"></i><span className="hide-mob">פרטי ספק</span></a>
                        <a style={{color:'#154360'}}className={`nav-item nav-link ${selectedTab === 2 ? 'active show' : ''}`} id="nav-profile-tab" onClick={() => handleTabClick(2)} data-toggle="tab" href="#nav-profile" role="tab" aria-controls="nav-profile" aria-selected="false"><i className="fa fa-book"></i>&nbsp;<span>ניהול הקטגוריות</span></a>
                        <a style={{color:'#154360'}} className={`nav-item nav-link ${selectedTab === 3 ? 'active show' : ''}`} id="nav-certificate-tab" onClick={() => handleTabClick(3)} data-toggle="tab" href="#nav-certificate" role="tab" aria-controls="nav-certificate" aria-selected="true"><i className="fa fa-book"></i>&nbsp;<span>יבוא מוצרים</span></a>
                    </div>
                </nav>

                {/* Buttons */}
                {selectedTab === 3 &&
                    <Product
                        id={id}
                        langData={langData}
                        langConfig={langConfig}
                        providerName={user.firstName + " " + user.lastName}
                        importUrl = {providerDetails.importUrl}

                    />

                }

                {selectedTab === 2 &&
                    <Categories
                        id={id}
                        langData={langData}
                        langConfig={langConfig}
                        isAdmin={isAdmin}
                        providerName={user.firstName + " " + user.lastName}
                        setChangeInCategories = {setChangeInCategories}
                        setSavedCategories = {setSavedCategories}
                    />}
   {user?.paymeDashboardLink == null &&
    <div style={warningContainerStyle}>
    <span style={warningIconStyle}>⚠️</span>
    <h4>ספק לא מחובר לפימי המוצרים אינם מוצגים באתר</h4>
  </div>          }
            {/* Form */}
            {selectedTab === 1 &&
            
             <div style={{margin: '60px'}}>
           <form style={{width: '70%'}}>
    <div className="form-group row my-4">
        <label htmlFor="firstName" className="col-sm-2 col-form-label">שם פרטי</label>
        <div className="col-sm-10" style={{width: '50%'}}>
            <input
                type="text"
                id="firstName"
                name="firstName"
                value={providerDetails.firstName}
                onChange={handleInputChange}
                disabled={!isEditing}
                className="form-control"
                
            />
        </div>
    </div>

    <div className="form-group row my-4">
        <label htmlFor="lastName" className="col-sm-2 col-form-label">שם משפחה</label>
        <div className="col-sm-10"style={{width: '50%'}}>
            <input
                type="text"
                id="lastName"
                name="lastName"
                value={providerDetails.lastName}
                onChange={handleInputChange}
                disabled={!isEditing}
                className="form-control"
            />
        </div>
    </div>

    <div className="form-group row my-4">
        <label htmlFor="email" className="col-sm-2 col-form-label">דואר אלקטרוני</label>
        <div className="col-sm-10" style={{width: '50%'}}>
            <input
                type="text"
                id="email"
                name="email"
                value={providerDetails.email}
                onChange={handleInputChange}
                disabled={!isEditing}
                className="form-control"
            />
        </div>
    </div>

    <div className="form-group row my-4">
        <label htmlFor="phoneNumber" className="col-sm-2 col-form-label">פלאפון</label>
        <div className="col-sm-10" style={{width: '50%'}}>
            <input
                type="text"
                id="phoneNumber"
                name="phoneNumber"
                value={providerDetails.phoneNumber}
                onChange={handleInputChange}
                disabled={!isEditing}
                className="form-control"
            />
        </div>
    </div>

    <div className="form-group row my-4">
        <label htmlFor="companyName" className="col-sm-2 col-form-label">שם חברה</label>
        <div className="col-sm-10" style={{width: '50%'}}>
            <input
                type="text"
                id="companyName"
                name="companyName"
                value={providerDetails.companyName}
                onChange={handleInputChange}
                disabled={!isEditing}
                className="form-control"
            />
        </div>
    </div>


    <div className="form-group row my-4">
        <label htmlFor="companyName" className="col-sm-2 col-form-label">ח.פ</label>
        <div className="col-sm-10" style={{width: '50%'}}>
            <input
                type="text"
                id="incCode"
                name="incCode"
                value={providerDetails.incCode}
                onChange={handleInputChange}
                disabled={!isEditing}
                className="form-control"
            />
        </div>
    </div>

    <div className="form-group row my-4">
        <label htmlFor="bankAccountId" className="col-sm-2 col-form-label">מספר חשבון בנק</label>
        <div className="col-sm-10" style={{width: '50%'}}>
            <input
                type="text"
                id="bankAccountId"
                name="bankAccountId"
                value={providerDetails.bankAccountId}
                onChange={handleInputChange}
                disabled={!isEditing}
                className="form-control"
            />
        </div>
    </div>
    <div className="form-group row my-4">
        <label htmlFor="bankAccountId" className="col-sm-2 col-form-label">אחוז הנחה</label>
        <div className="col-sm-10" style={{width: '50%'}}>
            <input
                type="text"
                id="discountPercent"
                name="discountPercent"
                value={providerDetails.discountPercent}
                onChange={handleInputChange}
                disabled={!isEditing}
                className="form-control"
            />
        </div>
    </div>
  {  
user?.paymeDashboardLink == null &&
    <button
        type="button"  // Ensure button type is explicitly set to "button"
        onClick={() => createSeller()}
                        className={`${Styles.btnStyle} my-4`}
                        style={{marginRight: '-2px' }}
                        >
                        התחברות לפיימי
   </button>
}
{
user?.paymeDashboardLink !== null &&
<>
<div className="form-group row my-4">
<label htmlFor="bankAccountId" className="col-sm-2 col-form-label">לינק ניהול פימי</label>
<div className="col-sm-10" style={{width: '50%'}}>
    <input
        type="text"
       // id="discountPercent"
      //  name="discountPercent"
        value={user?.paymeDashboardLink}
        onChange={handleInputChange}
        disabled={!isEditing}
        className="form-control"
    />
</div>
</div>
<div className="form-group row my-4">
<label htmlFor="bankAccountId" className="col-sm-2 col-form-label">mpl ספק</label>
<div className="col-sm-10" style={{width: '50%'}}>
    <input
        type="text"

        value={user?.sellerPaymeId}
        onChange={handleInputChange}
        disabled={!isEditing}
        className="form-control"
    />
</div>
</div>
</>

}


</form>
<div style={{width: '50%', display: 'flex', justifyContent: 'center', gap: '10px' }}>
    <Button
        variant="outlined"
        color="primary"
        onClick={handleSave}
        style={{ fontWeight: 'bold', width: '150px' }}
        disabled={!isEditing}
    >
        שמור
    </Button>
    <Button
        variant="outlined"
        color="error"
        onClick={handleDelete}
        style={{ fontWeight: 'bold', width: '150px' }}
        disabled={!isEditing}
    >
        מחק ספק
    </Button>
</div>
</div>
                }</Box>
        </>
    );

}
export default EditProvider;